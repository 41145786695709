/* eslint-disable func-names */
document.addEventListener('trix-initialize', function(e) {
  const editor = e.target;
  const toolbar = editor.toolbarElement;
  const tools = toolbar.querySelector('[data-trix-button-group="text-tools"]');

  const underlineButton =
    '<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" data-trix-key="u" title="underline" tabindex="-1">underline</button>';
  tools.insertAdjacentHTML('beforeend', underlineButton);
});
